<template>
  <div>
    <vx-card title="BANK BAY">
      <vs-table :data="bankBAY">

        <template slot="thead">
          <vs-th>ID</vs-th>
          <vs-th>For</vs-th>
          <vs-th>Name</vs-th>
          <vs-th>Number</vs-th>
          <vs-th>Type</vs-th>
          <vs-th>แสดงหน้าเว็บ</vs-th>
          <vs-th>ดึงยอดธนารคาร</vs-th>
          <vs-th>แอดเครดิตธนาคาร</vs-th>
          <vs-th></vs-th>
        </template>


        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.bank_id">
              {{tr.bank_id}}
            </vs-td>
            <vs-td :data="tr.bank_for">
              {{tr.bank_for}}
            </vs-td>
            <vs-td :data="tr.bank_fullname">
              {{tr.bank_fullname}}
            </vs-td>
            <vs-td :data="tr.bank_number">
              {{tr.bank_number}}
            </vs-td>
            <vs-td :data="typename[tr.bank_reserve]" v-if="tr.bank_reserve === false || tr.bank_reserve === 0" class="text-success">
              บัญชีหลัก
            </vs-td>
            <vs-td :data="typename[tr.bank_reserve]" v-if="tr.bank_reserve === true || tr.bank_reserve === 1" class="text-danger" >
              สำรอง
            </vs-td>
            <vs-td :data="typename[tr.bank_reserve]" v-if="tr.bank_show === false || tr.bank_show === 0" class="text-danger">
              ปิด
            </vs-td>
            <vs-td :data="typename[tr.bank_reserve]" v-if="tr.bank_show === true || tr.bank_show === 1 " class="text-success">
              เปิด
            </vs-td>
            <vs-td :data="typename[tr.bank_reserve]" v-if="tr.bank_run === false || tr.bank_run === 0" class="text-danger">
              ปิด
            </vs-td>
            <vs-td :data="typename[tr.bank_reserve]" v-if="tr.bank_run === true || tr.bank_run === 1 " class="text-success">
              เปิด
            </vs-td>
            <vs-td :data="typename[tr.bank_run_addcredit]" v-if="tr.bank_run_addcredit === false || tr.bank_run_addcredit === 0" class="text-danger">
              ปิด
            </vs-td>
            <vs-td :data="typename[tr.bank_run_addcredit]" v-if="tr.bank_run_addcredit === true || tr.bank_run_addcredit === 1 " class="text-success">
              เปิด
            </vs-td>
            <vs-td :data="tr.bank_number">
              <router-link :to="`/Bank/banklist/${bank}/${tr.bank_id}/${tr.bank_for}`">
                <vs-button type="border" size="small" icon-pack="feather" icon="icon-list" class="mr-2"
                  v-model="tr.bank_id">รายการเดินบัญชี</vs-button>
              </router-link>
            </vs-td>
            <vs-td :data="tr.bank_number">
              <router-link :to="`/Bank/banklistout/${bank}/${tr.bank_id}/${tr.bank_for}`">
                <vs-button type="border" size="small" icon-pack="feather" icon="icon-list" class="mr-2"
                  v-model="tr.bank_id">รายการเงินออกบัญชี</vs-button>
              </router-link>
            </vs-td>
            <vs-td>
              <vs-button :disabled="$store.state.AppActiveUser.permissions.bank.action ? false : true" @click="showUploadPopup(tr)" type="filled" size="small" color="warning" icon-pack="feather" icon="icon-upload" class="mr-2">
                อัพโหลด Statement
              </vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-popup title="อัพโหลด Statement (BAY)" :active.sync="uploadPopupSync">
        <div class="p-2 px-3">
        <h1 class="my-3 text-primary">อัพโหลด Statement ของบัญชี {{ currentUploadStatement.bank_for }}</h1>
        <p class="my-3">ชื่อบัญชี: {{ currentUploadStatement.bank_fullname }}</p>
        <p class="my-3">เลขที่บัญชี: {{ currentUploadStatement.bank_number }}</p>

        <p class="my-4 text-warning">
          โปรดเลือกไฟล์ Statement ของบัญชี <strong>{{ currentUploadStatement.bank_for }}</strong> เท่านั้น!
        </p>

        <p class="my-4 text-danger">
          ห้ามอัพโหลด Statement ของบัญชีหรือธนาคารอื่น ๆ เด็ดขาด! อาจจะทำให้มีรายการซ้ำได้
        </p>

        <vue-dropzone class="dropbox" ref="myVueDropzone" id="dropzone" :options="dropzoneOptions"></vue-dropzone>

        <p class="my-4 text-warning">
          กรุณากรอกเลขที่บัญชี <strong>{{ currentUploadStatement.bank_number }}</strong> เพื่อทำการยืนยัน
        </p>

        <vs-input placeholder="กรอกเลขที่บัญชี..." v-model="confirmBankNumber"/>

        <div class="text-center">
          <vs-button
            :disabled="!uploadStatementValidation"
            @click="submitFile"
            type="filled" size="small"
            color="success"
            icon-pack="feather"
            icon="icon-upload"
            class="mr-2 my-3"
          >อัพโหลด
          </vs-button>
          <vs-button
            @click="closeUploadPopup"
            type="filled" size="small"
            color="dark" icon-pack="feather"
            icon="icon-close" class="mr-2 my-3"
          >ปิด
          </vs-button>
        </div>
      </div>
      </vs-popup>
    </vx-card>

  </div>
</template>

<script>
import axios from '../../axios'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import log from '@/log'

export default {
  components: {
    vueDropzone: vue2Dropzone
  },
  data () {
    return {
      bankBAY: [],
      bank: 'bay_transaction',
      bankID: '',
      typename: ['บัญชีหลัก', 'บัญชีสำรอง'],
      bankRUN: ['ปิด', 'เปิด'],
      bankSHOW: ['ปิด', 'เปิด'],
      // File uploader
      uploadPopupSync: false,
      currentUploadStatement: {
        id: ''
      },
      file: '',
      error: null,
      confirmBankNumber: '',
      // Dropzone
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        paramName: 'file',
        autoProcessQueue: 'false',
        autoQueue: 'false',
        thumbnailWidth: 150,
        maxFilesize: 2,
        maxFiles: 1,
        acceptedFiles: '.html,.text',
        createImageThumbnails: false,
        dictDefaultMessage: 'ลากไฟล์ หรือกดคลิกเพื่ออัพโหลด'
      }
    }
  },
  computed: {
    uploadStatementValidation () {
      return this.currentUploadStatement.bank_number === this.confirmBankNumber
    }
  },
  async mounted () {
    await axios
      .get('bank/bank/bay')
      .then(response => (this.bankBAY = response.data))
  },
  methods: {
    closeUploadPopup () {
      this.currentUploadStatement = {
        id: ''
      }
      this.uploadPopupSync = false
      this.error = ''
      this.confirmBankNumber = ''
      this.$refs.myVueDropzone.removeAllFiles(true)
    },
    showUploadPopup (row) {
      this.uploadPopupSync = true
      this.currentUploadStatement = row
      this.$refs.myVueDropzone.removeAllFiles(true)
    },
    async submitFile () {

      const files = this.$refs.myVueDropzone.getAcceptedFiles()

      const formData = new FormData()
      formData.append('id', this.currentUploadStatement.bank_id)
      formData.append('file', files[0])
      const result = await axios.post('/statement/bay',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data; boundary=4LohFkPV2KNmGMu22nG8e3QHrXdJHnBd; charset=MS874'
          }
        }
      ).catch((error) => { this.error = error })

      if (!this.error && result.data.success) {
        const INFO = `BAY-${this.currentUploadStatement.bank_id} อัพโหลดไฟล์สำเร็จ เพิ่มข้อมูล ${result.data.count} แถว`
        this.$vs.notify({
          title: 'เพิ่ม Statement สำเร็จ',
          text: `อัพโหลดไฟล์สำเร็จ เพิ่มข้อมูล${result.data.count}แถว`,
          position: 'top-right',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })
        await log.agent(`BAY-${  this.currentUploadStatement.bank_id}`, 'UPLOAD_STATEMENT', result.data.count, INFO)
      } else if (!this.error && !result.data.success) this.$vs.notify({
        title: 'เพิ่ม Statement ไม่สำเร็จ',
        text: 'ไม่พบข้อมูลใหม่ หรือไฟล์ Statement เก่าเกินไป',
        position: 'top-right',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })
      else this.$vs.notify({
        title: 'เกิดข้อผิดพลาด',
        text: 'โปรดตรวจสอบไฟล์ หรือเลือกไฟล์ก่อนอัพโหลด',
        position: 'top-right',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })

      this.closeUploadPopup()
    }
  }
}

</script>

<!-- SASS styling -->
<style lang="scss">
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightgray;
  color: dimgray;
  padding: 10px 10px;
  min-height: 100px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: aliceblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
